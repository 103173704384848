import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Layout
import Grid from 'components/flex/Posts/Layout/Grid'
import { PostsProps } from './PostsShell'

const Section = styled.section`
  @media (min-width: 1200px) {
    margin-top: -8rem;
  }
`

const Blog: React.FC<PostsProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <Section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <Grid fields={fields} />
        </div>
      </BlogBink>
    </Section>
  )
}

export default Blog
