import React, { useState } from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

// Interface
import { PostsProps } from 'components/flex/Posts/PostsShell'

// Layout
import Post from 'components/flex/Posts/Layout/Post'

// SwiperCore modules
SwiperCore.use([Pagination])

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

const Grid: React.FC<PostsProps> = ({ fields, slider }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const blogBink = useBlogBink()

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef}>
        <div
          className={`row justify-content-center ${
            slider ? 'd-lg-flex d-none' : 'd-flex'
          } `}
        >
          <div className="col-lg-10">
            <ParseContent content={fields.description} />
            <div className="row gx-lg-5">
              {blogBink.posts.map((post, index) => {
                const { node }: any = post

                return (
                  <div
                    key={post.node.id}
                    className="col-6 col-md-4 mt-4 mt-xl-5"
                  >
                    <Post
                      node={node}
                      blogFields={fields}
                      index={index}
                      setCurrentIndex={setCurrentIndex}
                      currentIndex={currentIndex}
                    />
                  </div>
                )
              })}
            </div>
            {fields.link && (
              <div className="d-flex justify-content-center my-5">
                <ButtonSecondary to={fields.link?.url || '#'}>
                  {fields.link?.title}
                </ButtonSecondary>
              </div>
            )}
          </div>
        </div>
      </div>

      {marginLeft !== null && slider && (
        <SwiperContainer
          className="container d-lg-none"
          marginLeft={marginLeft}
        >
          <ParseContent content={fields.description} className="mb-5" />

          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <SwiperSlide key={post.node.id}>
                  <Post
                    node={node}
                    blogFields={fields}
                    index={index}
                    setCurrentIndex={setCurrentIndex}
                    currentIndex={currentIndex}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
          {fields.link && (
            <div className="d-flex justify-content-center my-5">
              <ButtonSecondary to={fields.link?.url || '#'}>
                {fields.link?.title}
              </ButtonSecondary>
            </div>
          )}
        </SwiperContainer>
      )}
    </>
  )
}

export default Grid
